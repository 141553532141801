import Web3Modal from "web3modal";

const providerOptions = {

};

const web3Modal = new Web3Modal({
  providerOptions // required
});

export default web3Modal;

